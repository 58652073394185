<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DE LOS CHECK-LIST PARA VERIFICAR CONDICIONES DE SEGURIDAD Y SALUD EN RUTA:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaCheckList.length}}</span>
                            <br>
                            <span class="text-muted">Verificaciones de condiciones de seguridad y salud en ruta</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de check-list de condiciones de seguridad y salud en ruta</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarCheckList = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo check list</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Verificaciones de condiciones de seguridad y salud en ruta </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaCheckList" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCheckList(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCheckList(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>

                                <template v-slot:cell(cantidadChecksSi)="param">
                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==2).length}}
                                </template>
                                <template v-slot:cell(cantidadChecksNo)="param">
                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==1).length}}
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo check list</span>
            </h6>
            <CButtonClose @click="modalRegistrarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCheckList)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Verificar que los integrantes de la burbuja estén usando:" active>
                                <b-row>
                                    
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Mascarilla
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[0].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[0].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Caretas faciales (solo cuando la burbuja se rompa)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[1].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[1].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Ropa adecuada
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[2].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[2].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar que cuentan con Provisión de soluciones">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Solución de alcohol al 70% o alcohol gel al 70%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[3].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[3].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Hipoclorito de sodio al 1%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[4].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[4].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Peróxido de hidrógeno al 0.5% (agua oxigenada)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[5].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[5].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Amonio Cuaternario / ozono / otros (especificar)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[6].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[6].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Tips recomendados para los actores del trekking
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[7].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[7].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar condición de salud de la burbuja">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Mal de altura
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[8].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[8].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Dolor muscular
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[9].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[9].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Cansancio
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[10].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[10].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Heridas Leves
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[11].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[11].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Picaduras / Escoriaciones
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[12].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[12].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Estado anímico
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[13].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[13].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar condición de seguridad en ruta">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Habilitación de caminos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[14].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[14].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Condición climática
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[15].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[15].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Vulnerabilidad de ruta
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[16].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[16].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar check list</span></h6>
            <CButtonClose @click="modalActualizarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCheckList)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Verificar que los integrantes de la burbuja estén usando:" active>
                                <b-row>
                                    
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Mascarilla
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[0].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[0].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Caretas faciales (solo cuando la burbuja se rompa)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[1].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[1].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Ropa adecuada
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[2].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[2].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar que cuentan con Provisión de soluciones">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Solución de alcohol al 70% o alcohol gel al 70%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[3].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[3].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Hipoclorito de sodio al 1%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[4].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[4].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Peróxido de hidrógeno al 0.5% (agua oxigenada)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[5].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[5].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Amonio Cuaternario / ozono / otros (especificar)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[6].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[6].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Tips recomendados para los actores del trekking
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[7].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[7].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar condición de salud de la burbuja">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Mal de altura
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[8].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[8].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Dolor muscular
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[9].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[9].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Cansancio
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[10].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[10].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Heridas Leves
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[11].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[11].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Picaduras / Escoriaciones
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[12].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[12].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Estado anímico
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[13].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[13].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar condición de seguridad en ruta">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Habilitación de caminos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[14].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[14].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Condición climática
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[15].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[15].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Vulnerabilidad de ruta
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[16].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[16].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import firebase from 'firebase';
const dbCheckListSSR = firebase.firestore().collection('checkListSSR');

export default {
    data() {
        return {
            disabled: false,
            listaCheckList: [],
            camposLote: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksSi",
                    label: "Checks si cumple",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksNo",
                    label: "Checks no cumple",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarCheckList: false,
            modalActualizarCheckList: false,
            comboSiNo: [{
                    value: 2,
                    text: 'Cumple'
                },
                {
                    value: 1,
                    text: 'No cumple'
                },
            ],
            datosCheckList: {
                idCheckList: '',
                idCliente: '',
                datosEvaluacion: [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                },]
            },
            datosCheckListActualizar: {
                idCheckList: '',
                idCliente: '',
                datosEvaluacion: [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                },]
            },
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarCheckList() {
            let me = this;
            dbCheckListSSR
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosCheckList.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaCheckList = [];
                    console.log(querySnapshot);
                    querySnapshot.forEach((doc) => {
                        me.listaCheckList.push({
                            idCheckList: doc.id,
                            idCliente: doc.data().idCliente,
                            datosEvaluacion: doc.data().datosEvaluacion,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaCheckList.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    me.filasTotales = me.listaCheckList.length;
                });
        },
        registrarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListSSR.add({
                    idCliente: me.datosCheckList.idCliente,
                    datosEvaluacion: me.datosCheckList.datosEvaluacion,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarCheckList = false
                    me.disabled = false;
                    me.listarCheckList();
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListSSR.doc(me.datosCheckListActualizar.idCheckList).update({
                    idCliente: me.datosCheckListActualizar.idCliente,
                    datosEvaluacion: me.datosCheckListActualizar.datosEvaluacion,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarCheckList = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarCheckList(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el check list?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCheckListSSR.doc(param.item.idCheckList).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirModalActualizarCheckList(param) {
            this.datosCheckListActualizar.idCheckList = param.item.idCheckList;
            this.datosCheckListActualizar.datosEvaluacion = param.item.datosEvaluacion;
            this.modalActualizarCheckList = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosCheckList.datosEvaluacion = [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                },];

        },
        resetModalActualizarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarCheckList: function (val) {
            if (val == false) {
                this.resetModalRegistrarCheckList();
            }
        },
        modalActualizarCheckList: function (val) {
            if (val == false) {
                this.resetModalActualizarCheckList();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCheckList.idCliente = this.datosCheckListActualizar.idCliente = user.idCliente;
            this.listarCheckList();
        }
    }

}
</script>